import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function CheckoutSummarySteps(props) {
  return (
    <Row className="checkout-steps">
      <Col className={props.step1 ? 'active' : ''}>Sign-In</Col>
      <Col className={props.step2 ? 'active' : ''}> Select Pending Orders</Col>
      <Col className={props.step3 ? 'active' : ''}> Summary Detail</Col>
      <Col className={props.step4 ? 'active' : ''}> Upload Summary</Col>
    </Row>
  );
}
