import { useEffect, useReducer, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import MessageBox from '../component/MessageBox';
import LoadingBox from '../component/LoadingBox';
import PendingOrder from '../component/PendingOrder';
//import data from '../data';
import axios from 'axios';
import logger from 'use-reducer-logger';
import { Helmet } from 'react-helmet-async';
import { Store } from '../Store';
import { Link, useNavigate } from 'react-router-dom';
import CheckoutSummarySteps from '../component/CheckoutSummarySteps';
import Badge from 'react-bootstrap/esm/Badge';
import SearchBox from '../component/SearchBox';
import { useRef } from 'react';
import { ViewportList } from 'react-viewport-list';
import { List } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';
import { getError } from '../util';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, orders: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function PendingOrdersScreen() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { summarycart, userInfo } = state;
  // const [{ loading, error, products }, dispatch] = useReducer(logger(reducer), {
  //   loading: true,
  //   error: '',
  // });

  //const [products, setProducts] = useState([]);
  useEffect(() => {
    if (!userInfo) {
      navigate('/signin');
    }
  }, [userInfo, summarycart.cartOrders, navigate]);

  const [{ loading, error, orders }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(
          `/api/orders/pending/${userInfo.code}`,
          {
            headers: {
              authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };

    fetchData();
  }, [userInfo]);

  // const renderRow = ({ index, key, style }) => (
  //   <div ref={ref} sm={12}>
  //     <div key={key} style={style} className="post">
  //       {/* {`${products[index].ItemDesc}-${products[index].Id}`} */}
  //       <Product product={products[index]}></Product>
  //     </div>
  //   </div>
  // );

  const renderRow = ({ index, key, style }) => (
    <div key={key} style={style} className="post">
      {/* <h3>{`${products[index].ItemDesc}-${products[index].Id}`}</h3>
          <p>{products[index].body}</p> */}
      <PendingOrder pendingorder={orders[index]}></PendingOrder>
    </div>
  );

  return (
    <div>
      <CheckoutSummarySteps step1 step2></CheckoutSummarySteps>
      <Helmet>
        <title>Booker App</title>
      </Helmet>
      <div className="products-heading">
        <h1>List of Pending Orders</h1>
        <Button
          href="/summarycart"
          disabled={
            orders && orders.length > 0
              ? orders[0].NoOfSummaryGenerated >= orders[0].TotalNoOfSummaries
              : true
          }
        >
          <Link to="/summarycart" className="nav-link">
            <i className="fa-solid fa-upload"></i>

            {summarycart.cartOrders.length > 0 && (
              <Badge pill bg="danger">
                {summarycart.cartOrders.length}
              </Badge>
            )}
          </Link>
        </Button>
        {/* <Link to="/placeorder" className="nav-link">
          Cart
        </Link> */}
      </div>
      <h5>
        Summary Remaining :{' '}
        {orders && orders.length !== 0
          ? orders[0].NoOfSummaryGenerated + '/' + orders[0].TotalNoOfSummaries
          : '0/0'}
        {/* {orders.length} */}
        {/* {orders.length !== 0
          ? orders[0].NoOfSummaryGenerated + '/' + orders[0].TotalNoOfSummaries
          : '0 / 0'} */}
      </h5>

      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div>
          <Card className="card-prod">
            <Card.Body>
              <Row>
                <Col xs={3} className="prod-col">
                  <strong>RefNo & Date</strong>
                </Col>
                <Col xs={4} className="prod-col">
                  <strong>Customer</strong>
                </Col>
                <Col
                  xs={3}
                  className="prod-col"
                  style={{ textAlign: 'center' }}
                >
                  <strong>Total</strong>
                </Col>
                <Col xs={2} className="prod-col">
                  <strong>Select</strong>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <List
            height={700}
            rowRenderer={renderRow}
            rowCount={orders.length}
            rowHeight={50}
            width={
              window.innerWidth > 1000
                ? window.innerWidth * 0.675
                : window.innerWidth * 0.95
            }
          />
        </div>
      )}
    </div>
  );
}

export default PendingOrdersScreen;
