import Axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import CheckoutSummarySteps from '../component/CheckoutSummarySteps';
import LoadingBox from '../component/LoadingBox';
import ListGroup from 'react-bootstrap/ListGroup';
import { Store } from '../Store';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';
import { toast } from 'react-toastify';
import { getError } from '../util';
import Badge from 'react-bootstrap/esm/Badge';
import NumberFormat from '../component/NumberFormat';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_REQUEST':
      return { ...state, loading: true };
    case 'CREATE_SUCCESS':
      return { ...state, loading: false };
    case 'CREATE_FAIL':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default function SummaryCartScreen() {
  const navigate = useNavigate();
  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { summarycart, userInfo } = state;

  useEffect(() => {
    if (!userInfo) {
      navigate('/');
    }
  }, [userInfo, navigate]);

  const placeSummaryHandler = async () => {
    try {
      dispatch({ type: 'CREATE_REQUEST' });
      const { data } = await Axios.post(
        '/api/orders/summary',
        {
          cartOrders: summarycart.cartOrders,
          bookerUserId: userInfo.code,
        },
        {
          headers: {
            authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      ctxDispatch({ type: 'SUMMARY_CART_CLEAR' });
      dispatch({ type: 'CREATE_SUCCESS' });
      localStorage.removeItem('cartOrders');
      navigate(`/summary/${data.summary.BsRefNo}`);
    } catch (err) {
      dispatch({ type: 'CREATE_FAIL' });
      toast.error(getError(err));
      // ctxDispatch({ type: 'USER_SIGNIN_AGAIN' });
      navigate('/pendingorders');
    }
  };

  return (
    <div>
      <CheckoutSummarySteps step1 step2 step3 />
      <Helmet>
        <title>Preview Summary</title>
      </Helmet>
      <div className="products-heading">
        <h1>Preview Summary</h1>
        <Button href="/summarycart">
          <Link to="/summarycart" className="nav-link">
            {/* <i className="fa-sharp fa-solid fa-cart-shopping"> </i> */}
            {/* <i class="fa-regular fa-caret-up"></i> */}
            {/* <i class="fa-regular fa-box"></i> */}
            {/* <i class="fa-regular fa-cart-flatbed-empty"></i> */}
            <i className="fa-solid fa-upload"></i>
            {/* <i class="fa-solid fa-cart-shopping"></i> */}
            {/* <i class="fa-solid fa-cart-shopping"></i> */}
            {/* <i class="fa-solid fa-cart-flatbed-empty"></i> */}
            {/* <i class="fa-solid fa-person-carry-box"></i> */}
            {/* <i class="fa-solid fa-cart-shopping"></i> */}
            {/* <i class="fa-light fa-cart-shopping"></i> */}
            {/* <i class="fa-solid fa-cart-circle-arrow-up"></i> */}
            {/* <i className="fa-thin fa-cart-minus"></i> */}

            {summarycart.cartOrders.length > 0 && (
              <Badge pill bg="danger">
                {summarycart.cartOrders.length}
              </Badge>
            )}
          </Link>
        </Button>
      </div>
      <h5>
        Summary Remaining :{' '}
        {summarycart.cartOrders && summarycart.cartOrders.length !== 0
          ? summarycart.cartOrders[0].NoOfSummaryGenerated +
            '/' +
            summarycart.cartOrders[0].TotalNoOfSummaries
          : '0/0'}
        {/* {orders.length} */}
        {/* {orders.length !== 0
          ? orders[0].NoOfSummaryGenerated + '/' + orders[0].TotalNoOfSummaries
          : '0 / 0'} */}
      </h5>
      <Row>
        <Col md={8}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Summary Detail</Card.Title>
              <ListGroup variant="flush">
                <ListGroupItem></ListGroupItem>
                {summarycart.cartOrders.map((order) => (
                  <ListGroup.Item key={order.Id}>
                    <Row className="align-items-center">
                      {/* <Col col={20}>{item.ItemDesc}</Col> */}
                      <Col col={1}>
                        {/* <span>{item.quantity}</span> */}
                        {/* {item.quantity} */}
                        {order.BknghRefno}
                        <br></br>
                        {order.BknghRefDate.toString()
                          .slice(0, 10)
                          .toString()
                          .trim()}
                      </Col>
                      <Col col={1}>
                        {order.CsName}
                        {/* <NumberFormat
                          // value={item.ItemRate}
                          decimal={2}
                        ></NumberFormat> */}
                      </Col>
                      <Col col={1}>
                        <NumberFormat
                          value={order.Amount}
                          decimal={2}
                        ></NumberFormat>{' '}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
                {/* <Link to="/products">Change Summary</Link> */}
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        style={{
                          margin: '10px',
                          marginRight: '20px',
                          height: '60px',
                        }}
                        type="button"
                        onClick={() => {
                          navigate('/pendingorders');
                        }}
                      >
                        <i className="fa-solid fa-rotate-left"></i>
                        <br></br>
                        Change
                      </Button>
                      <Button
                        style={{
                          margin: '10px',
                          height: '60px',
                          marginRight: 'auto',
                        }}
                        type="button"
                        onClick={placeSummaryHandler}
                        disabled={
                          summarycart.cartOrders.length === 0 ||
                          (summarycart.cartOrders &&
                          summarycart.cartOrders.length > 0
                            ? summarycart.cartOrders[0].NoOfSummaryGenerated >=
                              summarycart.cartOrders[0].TotalNoOfSummaries
                            : true)
                        }
                      >
                        <i className="fa-solid fa-upload"></i>
                        <br></br>
                        Create
                      </Button>
                    </div>
                    {loading && <LoadingBox></LoadingBox>}
                  </ListGroup.Item>
                </ListGroup>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
