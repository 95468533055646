import { createContext, useReducer } from 'react';
//import { json } from 'react-router-dom';

export const Store = createContext();

const initalState = {
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,
  cart: {
    customerInfo: localStorage.getItem('customerInfo')
      ? JSON.parse(localStorage.getItem('customerInfo'))
      : {},

    cartItems: localStorage.getItem('cartItems')
      ? JSON.parse(localStorage.getItem('cartItems'))
      : [],
  },
  products: localStorage.getItem('products')
    ? JSON.parse(localStorage.getItem('products'))
    : null,

  customers: localStorage.getItem('customers')
    ? JSON.parse(localStorage.getItem('customers'))
    : null,

  pendingorders: localStorage.getItem('pendingorders')
    ? JSON.parse(localStorage.getItem('pendingorders'))
    : {},

  summarycart: {
    cartOrders: localStorage.getItem('cartOrders')
      ? JSON.parse(localStorage.getItem('cartOrders'))
      : [],
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'CART_ADD_ITEM':
      const newItem = action.payload;
      const existItem = state.cart.cartItems.find(
        (item) => item.Id === newItem.Id
      );
      let cartItems = existItem
        ? state.cart.cartItems.map((item) =>
            item.Id === existItem.Id ? newItem : item
          )
        : newItem.quantity !== 0
        ? [...state.cart.cartItems, newItem]
        : [...state.cart.cartItems];

      cartItems = cartItems.filter((item) => item.quantity !== 0);
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      return { ...state, ...state.cart, cart: { ...state.cart, cartItems } };

    case 'CART_REMOVE_ITEM': {
      const cartItems = state.cart.cartItems.filter(
        (item) => item.Id !== action.payload.Id
      );
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      return { ...state.cart, cart: { ...state.cart, cartItems } };
    }
    case 'CART_CLEAR':
      return {
        ...state,
        cart: { ...state.cart, cartItems: [], customerInfo: {} },
      };

    case 'SUMMARY_CART_CLEAR':
      return {
        ...state,
        summarycart: {
          ...state.summarycart,
          cartOrders: [],
        },
      };

    case 'USER_SIGNIN':
      return { ...state, userInfo: action.payload };

    case 'GET_PRODUCTS':
      return { ...state, products: action.payload };

    case 'GET_CUSTOMERS':
      return { ...state, customers: action.payload };

    case 'USER_SIGNOUT':
      return {
        ...state,
        userInfo: null,
        cart: {
          cartItems: [],
          customerInfo: {},
        },
        products: null,
        customers: null,
      };

    case 'USER_SIGNIN_AGAIN':
      return {
        ...state,
        userInfo: null,
        products: null,
        customers: null,
      };

    case 'SAVE_CUSTOMER':
      return {
        ...state,
        cart: {
          ...state.cart,
          customerInfo: action.payload,
        },
      };

    case 'GET_PENDING_ORDERS':
      return {
        ...state,
        pendingorders: action.payload,
      };

    // case 'CART_ADD_ORDER':
    //   const newOrder = action.payload;
    //   console.log(newOrder, ' New Order');
    //   const existOrder = state.summarycart.cartOrders.find(
    //     (order) => order.Id === newOrder.Id
    //   );
    //   let cartOrders = existOrder
    //     ? state.summarycart.cartOrders.map((order) =>
    //         order.Id === existOrder.Id ? newOrder : order
    //       )
    //     : [...state.summarycart.cartOrders, newOrder];

    //   localStorage.setItem('cartOrders', JSON.stringify(cartOrders));
    //   return {
    //     ...state,
    //     ...state.summarycart,
    //     summarycart: { ...state.summarycart, cartOrders },
    //   };

    // case 'CART_ADD_ORDER':
    //   const newOrder = action.payload;
    //   console.log(state.summarycart.cartorders, ' ummary cart start');
    //   console.log(state.summarycart.cartorders, ' ummary cart');
    //   console.log(state.summarycart.cartorders, ' ummary cart end');
    //   console.log(state.summarycart.cartorders.length, ' ummary cart end');
    //   const existOrder =
    //     state.summarycart.cartorders.length === 0
    //       ? {
    //           ...state,
    //           summarycart: {
    //             ...state.summarycart.cartOrders,
    //             [...state.summarycart.cartOrders, newOrder],
    //           },
    //         }
    //       : state.summarycart.cartOrders.find(
    //           (order) => order.Id === newOrder.Id
    //         );

    //   let cartOrders = existOrder
    //     ? state.summarycart.cartOrders.map((order) =>
    //         order.Id === existOrder.Id ? newOrder : order
    //       )
    //     : [...state.summarycart.cartOrders, newOrder];

    //   localStorage.setItem('cartOrders', JSON.stringify(cartOrders));
    //   return {
    //     ...state,
    //     ...state.summarycart,
    //     summarycart: { ...state.summarycart, cartOrders },
    //   };

    // case 'CART_ADD_ORDER':
    //   const newOrder = action.payload;
    //   console.log(newOrder, ' new Order');
    //   console.log(state.summarycart.cartOrders, ' cart Orders');
    //   const existOrder1 = state.summarycart.cartOrders.find((order) =>
    //     console.log(order.Id, ' orderId', newOrder.Id, ' new order id')
    //   );
    //   const existOrder = state.summarycart.cartOrders.find(
    //     (order) => order.Id === newOrder.Id
    //   );
    //   console.log(existOrder, ' order exist');
    //   let cartOrders = existOrder
    //     ? state.summarycart.cartOrders.map((order) =>
    //         order.Id === existOrder.Id ? newOrder : order
    //       )
    //     : [...state.summarycart.cartOrders, newOrder];

    //   cartOrders = [...state.summarycart.cartOrders, cartOrders];
    //   localStorage.setItem('cartOrders', JSON.stringify(cartOrders));
    //   return {
    //     ...state,
    //     ...state.summarycart,
    //     summarycart: { ...state.summarycart, cartOrders },
    //   };

    case 'CART_ADD_ORDER':
      const newOrder = action.payload;
      const existOrder = state.summarycart.cartOrders.find(
        (order) => order.Id === newOrder.Id
      );
      let cartOrders = existOrder
        ? state.summarycart.cartOrders.map((order) =>
            order.Id === existOrder.Id ? newOrder : order
          )
        : [...state.summarycart.cartOrders, newOrder];

      localStorage.setItem('cartOrders', JSON.stringify(cartOrders));
      return {
        ...state,
        ...state.summarycart,
        summarycart: { ...state.summarycart, cartOrders },
      };

    // case 'CART_ADD_ITEM':
    //   const newItem = action.payload;
    //   const existItem = state.cart.cartItems.find(
    //     (item) => item.Id === newItem.Id
    //   );
    //   let cartItems = existItem
    //     ? state.cart.cartItems.map((item) =>
    //         item.Id === existItem.Id ? newItem : item
    //       )
    //     : newItem.quantity !== 0
    //     ? [...state.cart.cartItems, newItem]
    //     : [...state.cart.cartItems];

    //   cartItems = cartItems.filter((item) => item.quantity !== 0);
    //   localStorage.setItem('cartItems', JSON.stringify(cartItems));
    //   return { ...state, ...state.cart, cart: { ...state.cart, cartItems } };

    case 'CART_REMOVE_ORDER': {
      const cartOrders = state.summarycart.cartOrders.filter(
        (order) => order.Id !== action.payload.Id
      );
      localStorage.setItem('cartOrders', JSON.stringify(cartOrders));
      return {
        ...state,
        ...state.summarycart,
        summarycart: { ...state.summarycart, cartOrders },
      };
    }

    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initalState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
