import axios from 'axios';
import React, { useEffect, useReducer, useState, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import { getError } from '../util';
import Product from '../component/Product';
import LinkContainer from 'react-router-bootstrap/LinkContainer';
import CheckoutSteps from '../component/CheckoutSteps';
import Badge from 'react-bootstrap/esm/Badge';
import SearchBox from '../component/SearchBox';
import { List } from 'react-virtualized';

// const reducer = (state, action) => {
//   switch (action.type) {
//     case 'FETCH_REQUEST':
//       return { ...state, loading: true };
//     case 'FETCH_SUCCESS':
//       return {
//         ...state,
//         products: action.payload,
//         page: action.payload.page,
//         pages: action.payload.pages,
//         countProducts: action.payload.countProducts,
//         loading: false,
//       };
//     case 'FETCH_FAIL':
//       return { ...state, loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

export default function SearchScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const sp = new URLSearchParams(search); /// search?category=Pharma
  const category = sp.get('category') || 'all';
  const query = sp.get('query') || 'all';
  const { state } = useContext(Store);
  const { userInfo, products, cart } = state;
  const countProducts = products.lenght;
  const searchedProducts = products.filter(
    (product) =>
      product.ItemDesc.toLowerCase().includes(
        query === 'all' ? product.ItemDesc.toLowerCase() : query.toLowerCase()
      ) ||
      product.ItemCode.toLowerCase().includes(
        query === 'all' ? product.ItemCode.toLowerCase() : query.toLowerCase()
      ) ||
      product.ItemBonusText.toLowerCase().includes(
        query === 'all'
          ? product.ItemBonusText.toLowerCase()
          : query.toLowerCase()
      )
  );

  // const [{ loading, error, products, pages, countProducts }, dispatch] =
  //   useReducer(reducer, {
  //     loading: true,
  //     error: '',
  //   });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     console.log('goning to datbase');
  //     try {
  //       const { data } = await axios.get(
  //         `/api/products/search?page=${page}&query=${query}&category=${category}`
  //       );
  //       console.log('gone to datbase');
  //       dispatch({ type: 'FETCH_SUCCESS', payload: data });
  //       console.log('come from datbase', products);
  //     } catch (err) {
  //       dispatch({
  //         type: 'FETCH_FAIL',
  //         payload: getError(err),
  //       });
  //     }
  //   };
  //   fetchData();
  // }, [category, error, page, query]);

  // const [categories, setCategories] = useState([]);
  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const { data } = await axios.get('/api/products/categories');
  //       setCategories(data);
  //     } catch (err) {
  //       toast.error(getError(err));
  //     }
  //   };
  //   fetchCategories();
  // }, [dispatch]);

  // const getFilterUrl = (filter) => {
  //   const filterPage = filter.page || page;
  //   const filterCategory = filter.category || category;
  //   const filterQuery = filter.query || query;
  //   return `/search?category=${filterCategory}&query=${filterQuery}&category=${category}&page=${filterPage}`;
  // };

  const renderSearchedProducts = ({ index, key, style }) => (
    <div key={key} style={style} className="post">
      {/* <h3>{`${products[index].ItemDesc}-${products[index].Id}`}</h3>
        <p>{products[index].body}</p> */}
      <Product product={searchedProducts[index]}></Product>
    </div>
  );

  return (
    <div>
      <CheckoutSteps step1 step2 step3></CheckoutSteps>
      <Helmet>
        <title>Booker App</title>
      </Helmet>
      <div className="products-heading">
        <h1>Searched Products</h1>

        <Button>
          <Link to="/cart" className="nav-link">
            <i className="fa-sharp fa-solid fa-cart-shopping"> </i>

            {cart.cartItems.length > 0 && (
              <Badge pill bg="danger">
                {cart.cartItems.length} /
                {cart.cartItems.reduce(
                  (a, c) => Number(a) + Number(c.quantity),
                  0
                )}
              </Badge>
            )}
          </Link>
        </Button>
        {/* <Link to="/placeorder" className="nav-link">
          Cart
        </Link> */}
      </div>
      <SearchBox searchItem={'products'} />
      <Row>
        {/* <Col md={3}>
          <h3>Companies</h3>
          <div>
            <ul>
              <li>
                <Link
                  className={'all' === category ? 'text-bold' : ''}
                  to={getFilterUrl({ category: 'all' })}
                >
                  Any
                </Link>
              </li>
              {categories.map((c) => (
                <li key={c.Cmp_Code}>
                  <Link
                    className={c.Cmp_Code === category ? 'text-bold' : ''}
                    to={getFilterUrl({ category: c.Cmp_Code })}
                  >
                    {c.Cmp_Name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </Col> */}

        <Col md={12}>
          {
            // loading ? (
            //   <LoadingBox></LoadingBox>
            // ) : error ? (
            //   <MessageBox variant="danger">{error}</MessageBox>
            // ) :
            <div>
              <Row className="justify-content-between mb-3">
                <Col md={12}>
                  <div>
                    {countProducts === 0 ? 'No' : countProducts} Results
                    {query !== 'all' && ' : ' + query}
                    {category !== 'all' && ' : ' + category}
                    {query !== 'all' || category !== 'all' ? (
                      <Button
                        variant="light"
                        onClick={() => navigate('/products')}
                      >
                        <i className="fas fa-times-circle"></i>
                      </Button>
                    ) : null}
                  </div>
                </Col>
              </Row>
              {products.lenght === 0 && (
                <MessageBox>No Product Found</MessageBox>
              )}
              <Card className="card-prod">
                <Card.Body>
                  <Row>
                    <Col xs={6} className="prod-col">
                      <strong>Product</strong>
                    </Col>
                    <Col xs={2} className="prod-col">
                      <strong>Price</strong>
                    </Col>
                    <Col xs={3} className="prod-col">
                      <strong>Quantity</strong>
                    </Col>
                    <Col xs={1} className="prod-col">
                      <strong>
                        {' '}
                        <i className="fa-solid fa-cart-shopping"></i>
                      </strong>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {/* <Row>
                {products
                  .filter(
                    (product) =>
                      product.ItemDesc.toLowerCase().includes(
                        query === 'all'
                          ? product.ItemDesc.toLowerCase()
                          : query.toLowerCase()
                      ) ||
                      product.ItemCode.toLowerCase().includes(
                        query === 'all'
                          ? product.ItemCode.toLowerCase()
                          : query.toLowerCase()
                      )
                  )
                  .map((product) => (
                    // <Col sm={6} lg={4} className="mb-3" key={product.Id}>
                    <Col key={product.Id} sm={12} md={12} lg={12}>
                      <p>aaaaaa</p>
                      <Product product={product}></Product>
                    </Col>
                  ))}
              </Row> */}
              <List
                // itemSize={17}

                // width={window.innerWidth * 0.95}
                height={700}
                rowRenderer={renderSearchedProducts}
                rowCount={searchedProducts.length}
                rowHeight={50}
                width={
                  window.innerWidth > 1000
                    ? window.innerWidth * 0.675
                    : window.innerWidth * 0.95
                }
              />
            </div>
          }
        </Col>
      </Row>
    </div>
  );
}
