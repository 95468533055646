import { useEffect, useReducer, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import MessageBox from '../component/MessageBox';
import LoadingBox from '../component/LoadingBox';
import Product from '../component/Product';
//import data from '../data';
import axios from 'axios';
import logger from 'use-reducer-logger';
import { Helmet } from 'react-helmet-async';
import { Store } from '../Store';
import { Link, useNavigate } from 'react-router-dom';
import CheckoutSteps from '../component/CheckoutSteps';
import Badge from 'react-bootstrap/esm/Badge';
import SearchBox from '../component/SearchBox';
import { useRef } from 'react';
import { ViewportList } from 'react-viewport-list';
import { List } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

// import { FixedSizeList as List } from 'react-window';

// const reducer = (state, action) => {
//   switch (action.type) {
//     case 'FETCH_REQUEST':
//       return { ...state, loading: true };
//     case 'FETCH_SUCCESS':
//       return { ...state, loading: false, products: action.payload };
//     case 'FETCH_FAIL':
//       return { ...state, loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

function ProductList() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { cart, userInfo, products } = state;

  // const [{ loading, error, products }, dispatch] = useReducer(logger(reducer), {
  //   loading: true,
  //   error: '',
  // });

  //const [products, setProducts] = useState([]);
  useEffect(() => {
    if (!userInfo || Object.keys(cart.customerInfo).length === 0) {
      navigate('/signin');
    }
  }, [userInfo, cart.customerInfo, navigate]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     dispatch({ type: 'FETCH_REQUEST' });
  //     try {
  //       const result = await axios.get('/api/products');
  //       dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
  //       localStorage.setItem('products', JSON.stringify(result.data));
  //     } catch (err) {
  //       dispatch({ type: 'FETCH_FAIL', payload: err.message });
  //     }

  //     //setProducts(result.data);
  //   };
  //   fetchData();
  // }, []);
  // const renderRow = ({ index, key, style }) => (
  //   <div ref={ref} sm={12}>
  //     <div key={key} style={style} className="post">
  //       {/* {`${products[index].ItemDesc}-${products[index].Id}`} */}
  //       <Product product={products[index]}></Product>
  //     </div>
  //   </div>
  // );
  console.log(products, ' products');
  const renderProduct = ({ index, key, style }) => (
    <div key={key} style={style} className="post">
      {/* <h3>{`${products[index].ItemDesc}-${products[index].Id}`}</h3>
        <p>{products[index].body}</p> */}
      <Product product={products[index]}></Product>
    </div>
  );

  return (
    <div>
      <Col sm={12}>
        <Card>
          Customer :{' '}
          <strong>
            {cart.customerInfo.CsName} - {cart.customerInfo.MarketName}
          </strong>
          <Link to="/customer">Change customer</Link>
        </Card>
      </Col>
      <CheckoutSteps step1 step2 step3></CheckoutSteps>
      <Helmet>
        <title>Booker App</title>
      </Helmet>
      <div className="products-heading">
        <h1>List of Products</h1>

        <Button href="/cart">
          <Link to="/cart" className="nav-link">
            <i className="fa-sharp fa-solid fa-cart-shopping"> </i>

            {cart.cartItems.length > 0 && (
              <Badge pill bg="danger">
                {cart.cartItems.length} /
                {cart.cartItems.reduce(
                  (a, c) => Number(a) + Number(c.quantity),
                  0
                )}
              </Badge>
            )}
          </Link>
        </Button>
        {/* <Link to="/placeorder" className="nav-link">
          Cart
        </Link> */}
      </div>
      <SearchBox searchItem={'products'} />
      <Card className="card-prod">
        <Card.Body>
          <Row>
            <Col xs={6} className="prod-col">
              <strong>Product</strong>
            </Col>
            <Col xs={2} className="prod-col">
              <strong>Price</strong>
            </Col>
            <Col xs={3} className="prod-col">
              <strong>Quantity</strong>
            </Col>
            <Col xs={1} className="prod-col">
              <strong>
                {' '}
                <i className="fa-solid fa-cart-shopping"></i>
              </strong>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* <div className="products"> */}
      {/* {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : */}
      {/* {
          <Row>
            {products.map((product) => (
              <Col key={product.Id} sm={12} md={12} lg={12}>
                <Product product={product}></Product>
              </Col>
            ))}
          </Row>
        } */}

      {/* <div className="scroll-container" ref={ref}> */}
      {/* <ViewportList
            viewportRef={ref}
            items={products}
            itemMinSize={40}
            margin={8}
            initialPrerender={20}
          >
            {(item) => (
              <div key={item.Id} className="post">
                <Product product={item}></Product>
              </div>
            )}
          </ViewportList> */}
      {/* <AutoSizer>
            {({ height, width }) => ( */}
      <List
        // itemSize={17}

        // width={window.innerWidth * 0.95}
        margin={8}
        height={700}
        rowRenderer={renderProduct}
        rowCount={products.length}
        rowHeight={40}
        width={
          window.innerWidth > 1000
            ? window.innerWidth * 0.675
            : window.innerWidth * 0.95
        }
      />
      {/* )}
          </AutoSizer> */}
      {/* <Product product={products[{}.index]}></Product> */}
      {/* </div> */}
      {/* <List
          sm={12}
          width={1200}
          height={700}
          rowRenderer={renderRow}
          rowCount={products.length}
          rowHeight={40}
        /> */}
      {/* </div> */}
    </div>
  );
}

export default ProductList;
