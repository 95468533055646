import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
//import axios from "axios";
import { useContext, useState } from 'react';
import { Store } from '../Store';
import NumberFormat from './NumberFormat';

function PendingOrder(props) {
  const { pendingorder } = props;
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    summarycart: { cartOrders },
  } = state;

  const existOrder = cartOrders.find((x) => x.Id === pendingorder.Id);
  const selectedOrder = existOrder ? true : false;

  const LinkToCartHandler = async (orderInfo) => {
    if (!selectedOrder) {
      ctxDispatch({
        type: 'CART_ADD_ORDER',
        payload: { ...orderInfo },
      });
    } else {
      ctxDispatch({
        type: 'CART_REMOVE_ORDER',
        payload: { ...orderInfo },
      });
    }
    //localStorage.setItem('orderSummaryInfo', JSON.stringify(orderSummaryInfo));
  };
  return (
    <Card className="card-prod">
      <Card.Body className="card-prod-body">
        <Row className={'row-prod '}>
          <Col
            xs={3}
            className={'prod-col ' + (selectedOrder ? 'selected-row' : '')}
          >
            {pendingorder.BknghRefno}
            <br></br>
            {pendingorder.BknghRefDate.toString()
              .slice(0, 10)
              .toString()
              .trim()}
          </Col>
          {/* <Col
          xs={3}
          // className={
          //   'prod-col ' +
          //   (summaryorders.BsBknghRefno ===
          //   summaryCart.orderSummaryInfo.BsBknghRefno
          //     ? 'selected-row'
          //     : '')
          // }
        >
          {summaryorder.BknghRefDate.toString()
            .slice(0, 10)
            .toString()
            .trim()}
        </Col> */}
          <Col
            xs={4}
            className={'prod-col ' + (selectedOrder ? 'selected-row' : '')}
          >
            {pendingorder.CsName}
          </Col>
          <Col
            xs={3}
            className={'prod-col ' + (selectedOrder ? 'selected-row' : '')}
            style={{ textAlign: 'right' }}
          >
            <NumberFormat
              value={pendingorder.Amount}
              decimal={2}
            ></NumberFormat>
          </Col>
          {/* <Col
          xs={3}
          className={
            'prod-col ' +
            (ordersummary.CsCode === cart.customerInfo.CsCode
              ? 'selected-row'
              : '')
          }
        >
          {ordersummary.MarketName}
        </Col> */}
          <Col xs={2} className={'prod-col'}>
            <Button
              size="sm"
              style={{
                color: 'white',
                fontSize: 'xx-small',
                width: '55px',
                margin: '0 ',
                backgroundColor: selectedOrder ? '#5e4607' : '',
              }}
              variant="primary"
              type="submit"
              onClick={() => LinkToCartHandler(pendingorder)}
            >
              {selectedOrder ? 'Unselect' : 'Select'}
            </Button>
            {/* <Button
              variant="primary"
              size="sm"
              type="submit"
              onClick={() => LinkToCartHandler(ordersummary)}
            >
              Un-Select
            </Button> */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
    // <Card className="card-prod">
    //   <Card.Body className="card-prod-body">
    //     <Row>
    //       {/* <Link to={`/product/${product.Id}`}> */}
    //       {/* <Card.Text>
    //         <strong>
    //           {product.ItemDesc}-{product.ItemUnit}
    //         </strong>
    //       </Card.Text> */}
    //       {/* //</Link> */}
    //     </Row>
    //     <Row className={'row-prod ' + (selectedItem ? 'selected-row' : '')}>
    //       <Col xs={6} className="prod-col">
    //         {ordersummary.BknghRefNo}
    //         {/* -{ordersummary.BknghRefNo} */}
    //       </Col>
    //       {/* <Col xs={2} className="prod-col prod-price-col">
    //         <span className="rate-col">
    //           <NumberFormat value={ordersummary.BknghRefNo} decimal={2} />
    //         </span>
    //       </Col> */}
    //       {/* <Col className="number">
    //         <NumberFormat value={product.ItemDisc} decimal={2} /> %
    //       </Col>
    //       <Col className="number">
    //         <NumberFormat value={product.ItemSTax} decimal={2} />
    //       </Col> */}
    //       {/* </Row>
    //     <Row> */}
    //       <Col xs={3} className="prod-col">
    //         {/* <input
    //           type="button"
    //           className="qty-btn"
    //           variant="light"
    //           disabled={itemQuantity < 1}
    //           onClick={() => setQuantity(itemQuantity - 1)}
    //           value="++"
    //         /> */}
    //         {/* <i className="fas fa-minus-circle"></i> */}
    //         {/* </Button> */}
    //         {/* <InputGroup className="mb-3">
    //           <InputGroup.Text></InputGroup.Text>
    //           <Form.Control aria-label="Amount (to the nearest dollar)" />
    //         </InputGroup> */}
    //         {/* <input
    //           type="number"
    //           onFocus={(e) => e.target.select()}
    //           placeholder="Quantity"
    //           value={itemQuantity}
    //           onChange={(e) => setQuantity(e.target.value)}
    //         ></input> */}
    //         {/* <span>{item.quantity}</span>{' '} */}
    //         {/* <Button
    //           className="qty-btn"
    //           variant="light"
    //           onClick={() => setQuantity(Number(itemQuantity) + 1)}
    //         >
    //           <i className="fas fa-plus-circle qty-btn  "></i>
    //         </Button> */}

    //         <div className="input-group">
    //           <input
    //             type="button"
    //             value="-"
    //             className="button-minus"
    //             // data-field="quantity"
    //             disabled={itemQuantity < 1}
    //             onClick={() => setQuantity(itemQuantity - 1)}
    //           />
    //           <input
    //             onFocus={(e) => e.target.select()}
    //             type="number"
    //             step="1"
    //             max=""
    //             value={itemQuantity}
    //             name="quantity"
    //             className="quantity-field"
    //             placeholder="Quantity"
    //             onChange={(e) => setQuantity(e.target.value)}
    //           />
    //           <input
    //             type="button"
    //             value="+"
    //             className="button-plus"
    //             data-field="quantity"
    //             onClick={() => setQuantity(Number(itemQuantity) + 1)}
    //           />
    //         </div>
    //       </Col>
    //       <Col xs={1} className="prod-col">
    //         <span className="cart-span">
    //           <i className="fa-solid fa-cart-shopping"></i>

    //           <input
    //             className="button-cart"
    //             type="button"
    //             data-field="quantity"
    //             onClick={() => addToCartHandler(ordersummary)}
    //           />
    //         </span>
    //         {/* <Button
    //           // variant="light"
    //           className="qty-btn cart-btn"
    //           onClick={() => addToCartHandler(product)}
    //         >
    //           <i className="fa-solid fa-cart-shopping"></i>
    //         </Button> */}
    //       </Col>
    //     </Row>
    //   </Card.Body>
    // </Card>
  );
}

export default PendingOrder;
